<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
		>
			<span slot="noOptions">
				{{ $t("no_options") }}
			</span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"
		/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import HouseBuilding from "@/services/HousingBuildingServices";

	// Other
	import qs from "qs";
    import HousingTenantServices from "@/services/HousingTenantServices";

	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				selected: null,
				options: []
			}
		},
		watch: {
			value: {
				handler: function (newValue) {
					this.selected = setSelected(newValue, this.options);
				}
			},
			type: {
				handler: function (newValue) {
					this.clearOptions()
					if (newValue) {
						this.getOptions()
					}
				}
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			translateNSelected: translateNSelected,
			getOptions() {
				const config = {
					params: {
                        sort:'-id',
                        limit:-1
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false}),
				};
                HousingTenantServices.getAll(config)
				                       .then((response) => {
					                       const data = response.data.data;
					                       data.map((item) => {
						                       this.options.push({
							                       value: item.id,
							                       text:item.name+ ' ' + item.surname,
						                       });
					                       });
				                       })
				                       .then(() => {
					                       this.selected = setSelected(this.value, this.options);
				                       })
			},
			clearOptions() {
				this.selected = null;
				this.options = [];
			}
		}
	}
</script>
