<template>

    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">

                    <ValidationProvider name="type"
                                        rules="required"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox
                                :code="'housing_user_type'"
                                v-model="formData.type"
                            />

                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="student_program_id"
                                        :rules="formData.type=='student'?'required':''"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('Student')" v-if="formData.type=='student'">
                            <student-list-selectbox
                                :code="'housing_user_type'"
                                :validate-error="errors[0]"
                                v-model="formData.student_program_id"
                            />

                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="tenant_id"
                                        :rules="formData.type=='tenant'?'required':''"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('tenant')"
                                      v-if="formData.type=='tenant'">
                            <house-tenant-selectbox
                                :validate-error="errors[0]"
                                v-model="formData.tenant_id"
                            />

                        </b-form-group>
                    </ValidationProvider>

                    <b-form-group :label="$t('start_date')+' / '+$t('end_date')">
                        <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                            <v-date-picker v-model="formData.date"
                                           is-range
                                           :model-config="{type: 'string',mask: 'MM/D/YYYY'}"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center"
                                         :class="errors[0] ? 'box-border-color':''">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start"
                                                       v-on="inputEvents.start"
                                                       :placeholder="$t('start_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end"
                                                       v-on="inputEvents.end"
                                                       :placeholder="$t('end_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </template>
                            </v-date-picker>
                        </ValidationProvider>
                    </b-form-group>
                    <ValidationProvider name="discount_amount"
                                        rules="required"
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('discount_amount2')">
                            <b-form-input type="number" v-model="formData.discount_amount"
                                          :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>


                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HouseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";

import StudentListSelectbox from "@/components/interactive-fields/StudentListSelectbox.vue";
import HouseTenantSelectbox from "@/components/interactive-fields/HouseTenantSelectbox.vue";
import houseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";
import HousingReservationServices from "@/services/HousingReservationServices";
import HouseBedSelectbox from "@/components/interactive-fields/HouseBedSelectbox.vue";
import moment from "moment";


export default {
    components: {
        HouseBedSelectbox,
        HouseBuildingSelectbox,
        HouseTenantSelectbox,
        StudentListSelectbox,
        houseBuildingSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
            default: null
        },
        form:{
            type: Object,
            default:{}
        }
    },
    data() {
        return {
            formData: {},
        }
    },
    created() {
      this.formData.date=this.form
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formData.housing_bed_id = this.formId
                this.formData.check_in_date =moment(this.formData.date?.start, "MM/D/YYYY").format("MM/DD/YYYY");
                this.formData.check_out_date = moment(this.formData.date?.end, "MM/D/YYYY").format("MM/DD/YYYY");
                delete  this.formData.date
                HousingReservationServices.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
